import { Divider } from '@mui/material';

export const FullWidthDivider = ({ sx = {} }) => (
  <Divider
    sx={{
      maxWidth: '100vw',
      borderWidth: 4,
      borderColor: '#E9ECEF',
      mb: 1,
      mx: { xs: '-24px', md: '-16px' },
      ...sx,
    }}
  />
);
