import React from 'react';
import Spacer, { HORIZONTALL_AXIS } from '../../components/Spacer/Spacer';
import css from './ListingPage.module.css';

function SectionBadge(props) {
  const { isPart, frameSize, year, condition } = props;

  return (
    <div className={css.sectionBadge}>
      <div className={css.itemBadges}>
        {isPart ? (
          <div className={css.badgeRoot}>
            <span className={css.badgeText}>{condition}</span>
          </div>
        ) : (
          <>
            <div className={css.badgeRoot}>
              <span className={css.badgeText}>{frameSize}</span>
            </div>
            <Spacer axis={HORIZONTALL_AXIS} size={12} />
            { year ? <div className={css.badgeRoot}>
              <span className={css.badgeText}>{year}</span>
            </div> : null }
          </>
        )}
      </div>
    </div>
  );
}

export default SectionBadge;
