import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import Linkify from 'react-linkify';
import { compose } from 'redux';
import css from '../ListingPage.module.css';

import Collapsible from '../../../components/Collapsible/Collapsible';
import Spacer from '../../../components/Spacer/Spacer';
import { injectIntl } from '../../../util/reactIntl';

import BuySellIcon from './buy-sell.svg';
import ProtectionIcon from './protection.svg';
import SaveIcon from './save.svg';
import ShippingIcon from './shipping.svg';

function SectionShoppingDetails(props) {
  const { description, intl } = props;

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  return (
    <div className={css.sectionShoppingDetails}>
      <Collapsible openByDefault label={intl.formatMessage({ id: 'listing_page.shopping.label' })}>
        <Linkify componentDecorator={componentDecorator}>
          <Stack gap={2}>
            <Stack direction="row" gap={2} alignItems="flex-start">
              <Box component="img" mt="2px" src={BuySellIcon} alt="Buy/Sell Icon" />
              <Stack>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'listing_page.shopping.buy_sell.title' })}
                </Typography>
                <Typography variant="body1">
                  {intl.formatMessage({ id: 'listing_page.shopping.buy_sell.description' })}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" gap={2} alignItems="flex-start">
              <Box component="img" mt="2px" src={ProtectionIcon} alt="Protection Icon" />
              <Stack>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'listing_page.shopping.protection.title' })}
                </Typography>
                <Typography variant="body1">
                  {intl.formatMessage({ id: 'listing_page.shopping.protection.description' })}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" gap={2} alignItems="flex-start">
              <Box component="img" mt="2px" src={ShippingIcon} alt="Shipping Icon" />
              <Stack>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'listing_page.shopping.shipping.title' })}
                </Typography>
                <Typography variant="body1">
                  {intl.formatMessage({ id: 'listing_page.shopping.shipping.description' })}
                </Typography>
              </Stack>
            </Stack>

            <Stack direction="row" gap={2} alignItems="flex-start">
              <Box component="img" mt="2px" src={SaveIcon} alt="Save Icon" />
              <Stack>
                <Typography variant="body1" fontWeight="bold">
                  {intl.formatMessage({ id: 'listing_page.shopping.save.title' })}
                </Typography>
                <Typography variant="body1">
                  {intl.formatMessage({ id: 'listing_page.shopping.save.description' })}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Linkify>
        <Spacer size={24} />
      </Collapsible>
    </div>
  );
}

export default compose(injectIntl)(SectionShoppingDetails);
