import React from 'react';
import Linkify from 'react-linkify';
import { compose } from 'redux';
import css from './ListingPage.module.css';

import Collapsible from '../../components/Collapsible/Collapsible';
import Spacer from '../../components/Spacer/Spacer';
import { injectIntl } from '../../util/reactIntl';

function SectionSellerDetails(props) {
  const { description, intl } = props;

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  );

  return (
    <div className={css.sectionSellerDetails}>
      <Collapsible openByDefault label={intl.formatMessage({ id: 'listing_page.seller_details.label' })}>
        <Linkify componentDecorator={componentDecorator}>
          <p className={css.description}>{description}</p>
        </Linkify>
        <Spacer size={24} />
      </Collapsible>
    </div>
  );
}

export default compose(injectIntl)(SectionSellerDetails);
